import styled from 'styled-components'
import _ from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAsyncEffect } from '../../common/src/hooks/useAsyncEffect'
import { useDispatch } from 'react-redux'
import { Image, ProgressIndicator } from '@fluentui/react'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import { getFileExtensionIconURL } from '../../common/src/constants'
import { AlertModal } from '../../components/modal/AlertModal'
import { fileHelper, itemHelper } from '../../common/src/helpers'
import { externalLinkHandler } from '../../utils/externalLinkHandler'
import { FileContext } from '../../contexts'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'
import { toDate } from '../../common/src/helpers/dateHelper'
import { AttributePlaceholder } from '../../components/placeholder/AttributePlaceholder'
import { Placeholder, ResourcesSectionCard, SectionHeader, SectionTitle } from './resourcesSectionCommon'
import { getOneDriveFolderForItem } from '../../common/src/actions/itemsAPI'
import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Link,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  TableCellLayout,
  Toolbar,
  ToolbarButton,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { ArrowDownload, ArrowUpload, ClipboardLink, Delete, OpenFolder } from '../../components/BundledIcons'
import { useDownloadFiles } from '../../hooks/filesHooks'
import { isElectronApp } from '../../integrations/electron'
import { formatDate } from '../../utils/datefns'
import { useShowToastDidCopyLinkToClipboard } from '@/hooks/clipboardHooks.js'
import { copyToClipboard } from '@/common/src/helpers/clipboardUtils.js'

const FileIcon = styled(Image)`
  vertical-align: middle;
  max-height: 16px;
  max-width: 16px;
`

const DateText = styled(AttributePlaceholder)`
  font-size: 11px;
  font-weight: lighter;
  color: ${p => p.theme.palette.neutralDark};
  height: auto;
  margin: 4px 0 0;
`

const getDeleteConfirmationText = t => (filename, count) => {
  let message
  if (count === 1) {
    message = t('item.file.want_delete_file', { filename })
  } else {
    message = t('item.file.want_delete_count', { count })
  }
  return `${message} ${t('item.file.deleted_warning')}`
}

const timestampToFormattedDate = timestamp => {
  const date = toDate(timestamp)
  const text = date ? formatDate('ShortenedNormal')(date) : ''
  return _.replace(text, 'de ', '') // shorten text if Spanish
}

export const DateForResource = ({ timestamp }) => {
  const formattedDate = timestampToFormattedDate(timestamp)
  const { t } = useTranslation()
  return <DateText name={formattedDate} title={t('item.file.date_tooltip', { date: formattedDate })} />
}

export const useOneDriveFolder = ({ itemID, files }) => {
  const dispatch = useDispatch()
  const [oneDriveFolder, setOneDriveFolder] = useState('')
  useAsyncEffect(
    async getIsMounted => {
      setOneDriveFolder('')
      const file = files.find(fileHelper.isOneDriveStorage)
      if (!file) return
      const oneDriveData = await dispatch(getOneDriveFolderForItem(itemID))
      if (!getIsMounted()) {
        return
      }
      const link = oneDriveData?.payload?.folder_link
      setOneDriveFolder(link)
    },
    [itemID, files]
  )
  return oneDriveFolder
}

const CopyButtonCell = ({ item }) => {
  const { t } = useTranslation()
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const copyLink = useCallback(
    (event, link) => {
      copyToClipboard(link)
      showToastDidCopyLinkToClipboard()
      event.stopPropagation()
      event.preventDefault()
    },
    [showToastDidCopyLinkToClipboard]
  )
  return (
    <div className="flex w-full justify-end">
      <Tooltip content={t('item.file.copy_link')} relationship="label">
        <Button appearance="subtle" icon={<ClipboardLink />} onClick={event => copyLink(event, item.url)} />
      </Tooltip>
    </div>
  )
}

const FileNameCell = ({ item }) => {
  const { downloadFiles } = useDownloadFiles()
  const src = getFileExtensionIconURL(item.filename)
  const onClickLink = evt => {
    evt.stopPropagation()
    if (downloadFiles && isElectronApp()) {
      evt.preventDefault?.()
      const itemFile = item?.itemFile
      if (itemFile) {
        downloadFiles([itemFile])
      }
      return
    }
    const handled = externalLinkHandler(item.url)
    if (handled) {
      evt.preventDefault()
    }
  }
  return (
    <TableCellLayout
      description={<DateForResource timestamp={item.timestamp} />}
      media={
        <Link href={item.url} onClick={onClickLink} title={`${item.filename} (${item.url})`}>
          <FileIcon height={16} width={16} src={src} onClick={onClickLink} />
        </Link>
      }
    >
      <Link href={item.url} onClick={onClickLink}>
        {item.filename}
      </Link>
    </TableCellLayout>
  )
}

const FileNameHeader = () => {
  const { t } = useTranslation()
  return <div className="pl-6">{t('item.file.name_column')}</div>
}

const columns = [
  createTableColumn({
    columnId: 'file',
    renderHeaderCell: () => <FileNameHeader />,
    renderCell: item => <FileNameCell item={item} />,
  }),
  createTableColumn({
    columnId: 'actions',
    renderHeaderCell: () => null,
    renderCell: item => <CopyButtonCell item={item} />,
  }),
]

const COLUMN_WIDTH = {
  actions: 30,
}

export const FilesSectionContent = ({ onAddFile, item, readOnly = false, className }) => {
  const [state, setState] = useMergeState({
    loading: false,
    showAlertModalToDelete: false,
  })
  const files = itemHelper.getFiles(item)
  const itemID = itemHelper.getId(item)
  const { t } = useTranslation()
  const oneDriveFolderLink = useOneDriveFolder({ itemID, files })
  const { downloadFiles } = useDownloadFiles()

  const { loading, showAlertModalToDelete } = state
  const { deleteFiles } = useContext(FileContext)
  const filesItems = useMemo(() => {
    return mapValuesToJsArray(files, f => {
      return {
        filename: f.get('filename'),
        url: f.get('url'),
        itemFile: f,
        timestamp: f.get('timestamp'),
      }
    })
  }, [files])

  const [selectedRows, setSelectedRows] = useState(new Set())

  useEffect(() => {
    setSelectedRows(new Set())
  }, [filesItems])

  const downloadSelectedFiles = useCallback(() => {
    if (!selectedRows.size) {
      return
    }
    const selectedItems = Array.from(selectedRows).map(row => filesItems[row])
    const itemFiles = selectedItems.map(item => item.itemFile)
    return downloadFiles(itemFiles)
  }, [selectedRows, downloadFiles, filesItems])

  const deleteSelectedFiles = useCallback(async () => {
    if (!selectedRows.size) {
      return
    }
    const selectedItems = Array.from(selectedRows).map(row => filesItems[row])
    const itemFiles = _.map(selectedItems, item => item.itemFile)

    setState({
      showAlertModalToDelete: false,
      loading: true,
    })
    await deleteFiles({ itemFiles })
    setState({ loading: false })
  }, [deleteFiles, filesItems, selectedRows, setState])

  const wantToDeleteSelectedFiles = useCallback(() => {
    setState({ showAlertModalToDelete: true })
  }, [setState])

  const alertModalSubText = useMemo(() => {
    if (!selectedRows.size) {
      return null
    }
    const selectedItems = Array.from(selectedRows).map(row => filesItems[row])
    const firstSelectedItem = _.first(selectedItems)
    if (!firstSelectedItem) {
      return null
    }
    const { itemFile } = firstSelectedItem
    const filename = fileHelper.getFilename(itemFile)
    return getDeleteConfirmationText(t)(filename, selectedRows.size)
  }, [filesItems, selectedRows, t])

  const placeholder = !readOnly && <Placeholder>{t('item.file.placeholder')}</Placeholder>

  const deleteButton = (
    <Tooltip content={t('item.file.delete_files')} relationship="label">
      <ToolbarButton
        icon={<Delete />}
        onClick={selectedRows.size === 0 ? undefined : wantToDeleteSelectedFiles}
        disabled={loading}
      />
    </Tooltip>
  )
  const downloadButton = (
    <Tooltip content={t('item.file.download_files')} relationship="label">
      <ToolbarButton icon={<ArrowDownload />} onClick={selectedRows.size === 0 ? undefined : downloadSelectedFiles} />
    </Tooltip>
  )
  const showSelectPopover = selectedRows.size === 0

  return (
    <ResourcesSectionCard className={className}>
      <SectionHeader>
        <SectionTitle>{t('item_detail.files_section_title')}</SectionTitle>
        {!readOnly && (
          <Toolbar>
            {oneDriveFolderLink && (
              <Tooltip content={t('item_detail.open_folder_tooltip')} relationship="description">
                <ToolbarButton icon={<OpenFolder />} as="a" href={oneDriveFolderLink} target="_blank" />
              </Tooltip>
            )}
            <ToolbarButton disabled={loading} icon={<ArrowUpload />} onClick={onAddFile}>
              {t('item.file.upload')}
            </ToolbarButton>
            {showSelectPopover ? <FirstSelectPopover>{deleteButton}</FirstSelectPopover> : deleteButton}
            {showSelectPopover ? <FirstSelectPopover>{downloadButton}</FirstSelectPopover> : downloadButton}
          </Toolbar>
        )}
      </SectionHeader>
      {loading && <ProgressIndicator />}
      {files.size > 0 ? (
        <DataGrid
          items={filesItems}
          columns={columns}
          selectionMode="multiselect"
          selectedItems={selectedRows}
          onSelectionChange={(e, data) => {
            setSelectedRows(data.selectedItems)
          }}
          noNativeElements={false}
          as="table"
        >
          <DataGridHeader>
            <DataGridRow
              selectionCell={{
                className: 'print:!hidden',
                checkboxIndicator: { 'aria-label': 'Select all rows' },
              }}
            >
              {({ renderHeaderCell, columnId }) => (
                <DataGridHeaderCell
                  className={columnId === 'actions' ? 'print:!hidden' : undefined}
                  style={{ width: COLUMN_WIDTH[columnId] }}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody>
            {({ item, rowId }) => (
              <DataGridRow
                key={rowId}
                selectionCell={{
                  className: 'print:!hidden',
                  checkboxIndicator: { 'aria-label': 'Select row' },
                  style: { verticalAlign: 'middle' },
                }}
              >
                {({ renderCell, columnId }) => (
                  <DataGridCell
                    className={columnId === 'actions' ? 'print:!hidden' : undefined}
                    style={{ width: COLUMN_WIDTH[columnId] }}
                  >
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      ) : (
        placeholder
      )}
      <AlertModal
        title={t('item.file.delete_confirmation_title')}
        subText={alertModalSubText}
        open={showAlertModalToDelete}
        onDismiss={() => {
          setState({ showAlertModalToDelete: false })
        }}
        primaryActionText={t('general.delete')}
        onPrimaryActionClick={deleteSelectedFiles}
      />
    </ResourcesSectionCard>
  )
}

const FirstSelectPopover = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Popover>
      <PopoverTrigger disableButtonEnhancement>{children}</PopoverTrigger>
      <PopoverSurface>{t('item.file.first_select')}</PopoverSurface>
    </Popover>
  )
}
