import { ITEM_KEYS } from '../itemKeys'
import { FILTER_REDUCER_KEYS } from '../reducers/filtersKeys'
import { stateHelper } from '../helpers'

export const CONDITION_KEYS = {
  LESS_THAN: 'lessThan',
  GREATER_THAN: 'greaterThan',
}

export const TEXT_MODE_KEYS = {
  EXACT_NAME: 'exact_name',
  FULL_TEXT: 'full_text',
}

export const TAGS_MODE_KEYS = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
}

export const PROJECTS_MODE_KEYS = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
}

export const PROJECTS_SORT_TYPE = {
  NAME: 'name',
  INDEX: 'index',
  TIMESTAMP: 'timestamp',
  STARRED: 'starred',
}

export const PROJECTS_SHOW_ARCHIVED_OPTIONS = {
  ONLY_ARCHIVED: 'only_archived',
  ONLY_ACTIVE: 'only_active',
  ALL: 'all',
}

export const STATE_FILTER = {
  ALL_ITEMS: 'ALL_ITEMS',
  UNFINISHED: 'UNFINISHED',
  FINISHED: 'FINISHED',
}

export const GROUP_BY = {
  NONE: 'none',
  PROJECT_ALPHABETICAL: 'project_alphabetical',
}

export const FREQUENT_ITEM_FILTERS = {
  OVERDUE: 'overdue',
  THIS_WEEK: 'this_week',
  MODIFIED_SINCE_YESTERDAY: 'modified_since_yesterday',
  STARRED: 'starred',
  MY_ITEMS: 'my_items',
  NEXT_7_DAYS: 'next_7_days',
}

export const ITEMS_SORT_TYPE = {
  NAME: ITEM_KEYS.NAME,
  ICON: ITEM_KEYS.ICON,
  INDEX: ITEM_KEYS.INDEX,
  LAST_MODIFIED_TIMESTAMP: ITEM_KEYS.LAST_MODIFIED_TIMESTAMP,
  STARRED: 'starred',
  DUE_DATE: ITEM_KEYS.DUE_DATE,
  CREATION_DATE: ITEM_KEYS.CREATION_DATE,
  START_DATE: ITEM_KEYS.START_DATE,
  COMPLETION: ITEM_KEYS.COMPLETION_PERCENTAGE,
  OWNER_USERNAME: ITEM_KEYS.OWNER_USERNAME,
  QUADRANT: ITEM_KEYS.QUADRANT,
  PROJECTS: ITEM_KEYS.PROJECTS,
  EFFORT: ITEM_KEYS.EFFORT,
  COMPLETION_DATE: ITEM_KEYS.COMPLETION_DATE,
  CREATOR_USERNAME: ITEM_KEYS.CREATOR_USERNAME,
}

export const ITEMS_DATE_TYPE = {
  LAST_MODIFIED_DATE: 'lastModifiedDate',
  DUE_DATE: 'dueDate',
  START_DATE: 'startDate',
}

export const ACTION_TYPES = {
  SET_DEFAULT: 'FILTERS/SET_DEFAULT',

  SET_DEFAULT_PROJECTS: 'FILTERS/SET_DEFAULT_PROJECTS',
  SET_PROJECTS_SORT_TYPE: 'FILTERS/SET_PROJECTS_SORT_TYPE',
  SET_PROJECTS_SORT_ASCENDING: 'FILTERS/SET_PROJECTS_SORT_ASCENDING',
  SET_PROJECTS_TEXT: 'FILTERS/SET_PROJECTS_TEXT',
  SET_PROJECTS_USER_GROUP: 'FILTERS/SET_PROJECTS_USER_GROUP',
  SET_PROJECTS_TAGS: 'FILTERS/SET_PROJECTS_TAGS',
  SET_PROJECTS_TAGS_MODE: 'FILTERS/SET_PROJECTS_TAGS_MODE',
  ADD_PROJECT_TAG: 'FILTERS/ADD_PROJECT_TAGS',
  REMOVE_PROJECT_TAG: 'FILTERS/REMOVE_PROJECT_TAGS',
  SET_PROJECTS_SHOW_ARCHIVED: 'FILTERS/SET_PROJECTS_SHOW_ARCHIVED',

  SET_DEFAULT_ITEMS: 'FILTERS/SET_DEFAULT_ITEMS',
  SET_FREQUENT_ITEMS: 'FILTERS/SET_FREQUENT_ITEMS',
  SET_ITEMS_SORT_TYPE: 'FILTERS/SET_ITEMS_SORT_TYPE',
  SET_ITEMS_SORT_ASCENDING: 'FILTERS/SET_ITEMS_SORT_ASCENDING',
  SET_ITEMS_STARRED_FIRST: 'FILTERS/SET_ITEMS_STARRED_FIRST',
  SET_ITEMS_STATE: 'FILTERS/SET_ITEMS_STATE',
  SET_ITEMS_QUADRANT: 'FILTERS/SET_ITEMS_QUADRANT',
  SET_ITEMS_TEXT: 'FILTERS/SET_ITEMS_TEXT',
  SET_ITEMS_TEXT_MODE: 'FILTERS/SET_ITEMS_TEXT_MODE',
  SET_ITEMS_OWNERS: 'FILTERS/SET_ITEMS_OWNERS',
  ADD_ITEM_OWNER: 'FILTERS/ADD_ITEM_OWNER',
  REMOVE_ITEM_OWNER: 'FILTERS/REMOVE_ITEM_OWNER',
  SET_ITEMS_NO_OWNER: 'FILTERS/SET_ITEMS_NO_OWNER',
  SET_ITEMS_FOLLOWED: 'FILTERS/SET_ITEMS_FOLLOWED',
  SET_ITEMS_DATE_TYPE: 'FILTERS/SET_ITEMS_DATE_TYPE',
  SET_ITEMS_START_DATE_TIMESTAMP: 'FILTERS/SET_ITEMS_START_DATE_TIMESTAMP',
  SET_ITEMS_END_DATE_TIMESTAMP: 'FILTERS/SET_ITEMS_END_DATE_TIMESTAMP',
  SET_ITEMS_PROGRESS: 'FILTERS/SET_ITEMS_PROGRESS',
  SET_ITEMS_TAGS: 'FILTERS/SET_ITEMS_TAGS',
  SET_ITEMS_TAGS_MODE: 'FILTERS/SET_ITEMS_TAGS_MODE',
  ADD_ITEM_TAG: 'FILTERS/ADD_ITEMS_TAGS',
  REMOVE_ITEM_TAG: 'FILTERS/REMOVE_ITEMS_TAGS',
  SET_ITEM_PROJECT: 'FILTERS/SET_ITEM_PROJECT',
  SET_ITEM_PROJECTS_MODE: 'FILTERS/SET_ITEM_PROJECTS_MODE',
  SET_ITEM_STARRED_PROJECT: 'FILTERS/SET_ITEM_STARRED_PROJECT',
  SET_CUSTOM_FILTER: 'FILTERS/SET_CUSTOM_FILTER',
  SET_ITEMS_GROUP_BY: 'FILTERS/SET_ITEMS_GROUP_BY',
}

export const setCustomFilter = (customFilter, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_CUSTOM_FILTER,
  payload: {
    filtersType,
    customFilter,
  },
})

export const setDefaultFilter = () => ({
  type: ACTION_TYPES.SET_DEFAULT,
})

export const setDefaultFilterProjects = (filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_DEFAULT_PROJECTS,
  payload: { filtersType },
})

// Projects
export const setProjectsSortType = (sortType, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_SORT_TYPE,
  payload: { sortType, filtersType },
})

export const setProjectsSortAscending = (isAscending, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_SORT_TYPE,
  payload: { isAscending, filtersType },
})

export const setProjectsTextFilter = (text, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_TEXT,
  payload: { text, filtersType },
})

export const setProjectsUserGroupFilter = (userGroupID, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_USER_GROUP,
  payload: { userGroupID, filtersType },
})

export const setProjectsTagsFilter = (tagNames, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_TAGS,
  payload: { tagNames, filtersType },
})

export const setProjectsTagsModeFilter = (tagsMode, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_TAGS_MODE,
  payload: { tagsMode, filtersType },
})

export const addProjectTagFilter = (tagName, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.ADD_PROJECT_TAG,
  payload: { tagName, filtersType },
})

export const removeProjectTagFilter = (tagName, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.REMOVE_PROJECT_TAG,
  payload: { tagName, filtersType },
})

export const setProjectsShowArchivedFilter = (showArchived, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => ({
  type: ACTION_TYPES.SET_PROJECTS_SHOW_ARCHIVED,
  payload: { showArchived, filtersType },
})

// Items
export const setDefaultFilterItems = (filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_DEFAULT_ITEMS,
  payload: { filtersType },
})

export const setItemsSortType = (sortType, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_SORT_TYPE,
  payload: { filtersType, sortType },
})

export const setItemsSortAscending = (isAscending, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_SORT_ASCENDING,
  payload: { filtersType, isAscending },
})

export const setItemsStarredFirst = (starredFirst, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_STARRED_FIRST,
  payload: { filtersType, starredFirst },
})

export const setItemsStateFilter = (itemsState, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_STATE,
  payload: { filtersType, itemsState },
})

export const setItemsQuadrantFilter = (quadrant, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_QUADRANT,
  payload: { filtersType, quadrant },
})

export const setItemsTextFilter = (text, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_TEXT,
  payload: { filtersType, text },
})

export const setItemsTextModeFilter = (textMode, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_TEXT_MODE,
  payload: { filtersType, textMode },
})

export const setItemsOwnerFilter = (emails, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_OWNERS,
  payload: { filtersType, emails },
})

export const addItemOwnerFilter = (email, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.ADD_ITEM_OWNER,
  payload: { filtersType, email },
})

export const removeItemOwnerFilter = (email, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.REMOVE_ITEM_OWNER,
  payload: { filtersType, email },
})

export const setItemsNoOwnerFilter = (noOwner, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_NO_OWNER,
  payload: { filtersType, noOwner },
})

export const setItemsFollowedFilter = (followed, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_FOLLOWED,
  payload: { filtersType, followed },
})

export const setItemsDateTypeFilter = (dateType, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_DATE_TYPE,
  payload: { filtersType, dateType },
})

export const setItemsStartDateTimestampFilter = (timestamp, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_START_DATE_TIMESTAMP,
  payload: { filtersType, timestamp },
})

export const setItemsEndDateTimestampFilter = (timestamp, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_END_DATE_TIMESTAMP,
  payload: { filtersType, timestamp },
})

export const setItemsProgressFilter = (
  progress,
  condition = CONDITION_KEYS.GREATER_THAN,
  filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX
) => ({
  type: ACTION_TYPES.SET_ITEMS_PROGRESS,
  payload: {
    filtersType,
    progress,
    condition,
  },
})

export const setItemsTagsFilter = (tagNames, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_TAGS,
  payload: { filtersType, tagNames },
})

export const addItemTagFilter = (tagName, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.ADD_ITEM_TAG,
  payload: { filtersType, tagName },
})

export const setItemTagsModeFilter = (tagsMode, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_TAGS_MODE,
  payload: { filtersType, tagsMode },
})

export const removeItemTagFilterFor = (tagName, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.REMOVE_ITEM_TAG,
  payload: { filtersType, tagName },
})

export const setItemProjectFilter = (projectIds, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEM_PROJECT,
  payload: { filtersType, projectIds },
})

export const setItemProjectsMode = (mode, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEM_PROJECTS_MODE,
  payload: { filtersType, mode },
})

export const setItemStarredProjectFilter = (value, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEM_STARRED_PROJECT,
  payload: { filtersType, value },
})

export const setFrequentItemFilter =
  (filterName, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) =>
  (dispatch, getState) => {
    const state = getState()
    const meEmail = stateHelper.getMeEmail(state)
    return dispatch({
      type: ACTION_TYPES.SET_FREQUENT_ITEMS,
      payload: { filtersType, filterName, meEmail },
    })
  }

export const setItemsGroupByFilter = (groupBy, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => ({
  type: ACTION_TYPES.SET_ITEMS_GROUP_BY,
  payload: { filtersType, groupBy },
})
