import itemHelper from './itemHelper'
import tagHelper from './tagHelper'
import { FILTER_REDUCER_KEYS } from '../reducers/filtersKeys'
import {
  CONDITION_KEYS,
  GROUP_BY,
  ITEMS_DATE_TYPE,
  ITEMS_SORT_TYPE,
  PROJECTS_MODE_KEYS,
  STATE_FILTER,
  TAGS_MODE_KEYS,
  TEXT_MODE_KEYS,
} from '../actions/filtersActions'
import _ from 'lodash'
import { ITEM_KEYS } from '../itemKeys'
import { timestampInSecondsToIsoString } from './dateHelper'
import {
  emailPredicateGenerator,
  textPredicateGenerator,
  datePredicateGenerator,
  tagsPredicateGenerator,
  isFollowedPredicateGenerator,
  projectItemPredicateGenerator,
} from './filtersHelper'
import { helper as graphResourceHelper } from './graph/graphResourceBase'
import * as stringHelper from './stringHelper'
import { DEVELOPMENT } from '../environment'
import { projectHelper } from '@/common/src/helpers/index'

const STATE_PREDICATES = {
  [STATE_FILTER.ALL_ITEMS]: state => state <= itemHelper.STATE.DONE,
  [STATE_FILTER.FINISHED]: state => state === itemHelper.STATE.DONE,
  [STATE_FILTER.UNFINISHED]: state => state === itemHelper.STATE.UNFINISHED,
}

const quadrantPredicateGenerator = quadrantTarget => {
  if (quadrantTarget < 0) {
    return () => true
  }
  return q => quadrantTarget === q
}

const getDateAccordingToDateType = (dateType, item) => {
  if (!dateType || !item) {
    return null
  }
  switch (dateType) {
    case ITEMS_DATE_TYPE.LAST_MODIFIED_DATE:
      return itemHelper.getLastModifiedTimestamp(item)
    case ITEMS_DATE_TYPE.DUE_DATE:
      return itemHelper.getDueTimestamp(item)
    case ITEMS_DATE_TYPE.START_DATE:
      return itemHelper.getStartTimestamp(item)
    default:
      return null
  }
}

const progressPredicateGenerator = (progressTarget, condition) => {
  if (progressTarget === 0) {
    return () => true
  }

  return progress => {
    if (condition === CONDITION_KEYS.GREATER_THAN) {
      return progress >= progressTarget
    }
    return progress <= progressTarget
  }
}

export const createItemFilter = (filters, meEmail) => {
  const state = filters.get(FILTER_REDUCER_KEYS.STATE)
  const quadrant = filters.get(FILTER_REDUCER_KEYS.QUADRANT)
  const text = filters.get(FILTER_REDUCER_KEYS.TEXT)
  const owners = filters.get(FILTER_REDUCER_KEYS.OWNERS)
  const noOwner = filters.get(FILTER_REDUCER_KEYS.NO_OWNER)
  const followed = filters.get(FILTER_REDUCER_KEYS.FOLLOWED)
  const dateType = filters.get(FILTER_REDUCER_KEYS.DATE_TYPE)
  const startDate = filters.get(FILTER_REDUCER_KEYS.START_DATE)
  const endDate = filters.get(FILTER_REDUCER_KEYS.END_DATE)
  const progressCondition = filters.get(FILTER_REDUCER_KEYS.PROGRESS_CONDITION)
  const progress = filters.get(FILTER_REDUCER_KEYS.PROGRESS)
  const tagNames = filters.get(FILTER_REDUCER_KEYS.TAGS)
  const tagsMode = filters.get(FILTER_REDUCER_KEYS.TAGS_MODE)
  const projects = filters.get(FILTER_REDUCER_KEYS.PROJECT_IDS)
  const projectsMode = filters.get(FILTER_REDUCER_KEYS.PROJECTS_MODE)

  const statePredicate = STATE_PREDICATES[state]
  const quadrantPredicate = quadrantPredicateGenerator(quadrant)
  const ownersPredicate = emailPredicateGenerator(owners, noOwner)
  const textPredicate = textPredicateGenerator(text)
  const isFollowedPredicate = isFollowedPredicateGenerator(followed, meEmail)
  const datePredicate = datePredicateGenerator(startDate, endDate)
  const progressPredicate = progressPredicateGenerator(progress, progressCondition)
  const tagsPredicate = tagsPredicateGenerator(tagNames, tagsMode)
  const projectPredicate = projectItemPredicateGenerator(projects, projectsMode)
  return item => {
    const itemDate = getDateAccordingToDateType(dateType, item)
    return (
      statePredicate(itemHelper.getState(item)) &&
      quadrantPredicate(itemHelper.getQuadrant(item)) &&
      progressPredicate(itemHelper.getCompletionPercentage(item)) &&
      ownersPredicate(itemHelper.getOwnerUsername(item)) &&
      isFollowedPredicate(item) &&
      textPredicate(itemHelper.getName(item)) &&
      datePredicate(itemDate) &&
      tagsPredicate(itemHelper.getTags(item)) &&
      projectPredicate(itemHelper.getProjectIdd(item))
    )
  }
}

export const createTagFilter = tagNames => {
  const tagsPredicate = tagsPredicateGenerator(tagNames)
  return item => tagsPredicate(itemHelper.getTags(item))
}

const predicateGenerator = helper => {
  const byName = (a, b) => {
    const aValue = helper.getName(a)
    const bValue = helper.getName(b)
    return stringHelper.sortAfterToLower(aValue, bValue)
  }
  return {
    [ITEMS_SORT_TYPE.NAME]: byName,
    [ITEMS_SORT_TYPE.INDEX]: (a, b) => {
      const aValue = helper.getIndex(a)
      const bValue = helper.getIndex(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP]: (a, b) => {
      const aValue = helper.getLastModifiedTimestamp(a)
      const bValue = helper.getLastModifiedTimestamp(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.CREATION_DATE]: (a, b) => {
      const aValue = helper.getCreationTimestamp(a)
      const bValue = helper.getCreationTimestamp(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.START_DATE]: (a, b) => {
      const aValue = helper.getStartTimestamp(a)
      const bValue = helper.getStartTimestamp(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.STARRED]: (a, b) => {
      const aValue = _.toInteger(helper.isStarred(a))
      const bValue = _.toInteger(helper.isStarred(b))
      // Starred items should always go first disregarding SORT_DIRECTION, so it makes sense to invert this sorting
      return bValue - aValue
    },
    [ITEMS_SORT_TYPE.ICON]: (a, b) => {
      const aValue = helper.getIconNameSafely(a)
      const bValue = helper.getIconNameSafely(b)
      return stringHelper.sortAfterToLower(aValue, bValue)
    },
    [ITEMS_SORT_TYPE.DUE_DATE]: (a, b) => {
      const aValue = helper.getDueTimestamp(a)
      const bValue = helper.getDueTimestamp(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.COMPLETION_DATE]: (a, b) => {
      const aValue = helper.getCompletionTimestamp(a)
      const bValue = helper.getCompletionTimestamp(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.COMPLETION]: (a, b) => {
      const aValue = helper.getCompletionPercentage(a)
      const bValue = helper.getCompletionPercentage(b)
      return aValue - bValue
    },
    [ITEMS_SORT_TYPE.OWNER_USERNAME]: (a, b) => {
      const aValue = helper.getOwnerUsername(a)
      const bValue = helper.getOwnerUsername(b)
      return stringHelper.sortAfterToLower(aValue, bValue)
    },
    [ITEMS_SORT_TYPE.CREATOR_USERNAME]: (a, b) => {
      const aValue = helper.getCreatorUsername(a)
      const bValue = helper.getCreatorUsername(b)
      return stringHelper.sortAfterToLower(aValue, bValue)
    },
    [ITEMS_SORT_TYPE.QUADRANT]: (a, b) => {
      const aValue = helper.getQuadrant(a)
      const bValue = helper.getQuadrant(b)
      const diff = aValue - bValue
      return diff === 0 ? byName(a, b) : diff
    },
    [ITEMS_SORT_TYPE.EFFORT]: (a, b) => {
      const aValue = helper.getEstimatedEffort(a) ?? 0
      const bValue = helper.getEstimatedEffort(b) ?? 0
      const diff = aValue - bValue
      return diff === 0 ? byName(a, b) : diff
    },
  }
}

export const ITEMS_SORT_PREDICATES = predicateGenerator(itemHelper)
export const GRAPH_RESOURCE_SORT_PREDICATES = predicateGenerator(graphResourceHelper)

/**
 * Memo, we just want to log these comments once, in order to avoid thousands of logs
 * Used inside resourceHelper Proxy
 */
const missingMethodCounter = {}

/**
 * Helper wrapper for items and graphResources, so that's easy to reuse the code we generated for items
 * For it to work, methods used in 'predicateGenerator' should be present in both itemHelper and graphResourceHelper.
 * Otherwise, it returns 0
 */
const resourceHelper = _.mapValues(itemHelper, (fn, name) => {
  return (resource, ...rest) => {
    const callFn = providedHelper => {
      const fn = providedHelper[name]
      if (fn) {
        return fn(resource, ...rest)
      }
      if (DEVELOPMENT && !missingMethodCounter[name]) {
        missingMethodCounter[name] = true
        console.log('missing method', name)
      }
      return 0
    }
    if (graphResourceHelper.isGraphResource(resource)) {
      return callFn(graphResourceHelper)
    }
    return callFn(itemHelper)
  }
})

export const ITEM_AND_GRAPH_RESOURCE_SORT_PREDICATES = predicateGenerator(resourceHelper)

const hasDueDatePredicate = (a, b) => {
  const aValue = itemHelper.getDueTimestamp(a) === 0 ? 1 : 0
  const bValue = itemHelper.getDueTimestamp(b) === 0 ? -1 : 0
  return aValue + bValue
}

export const createItemSortDescriptor = filters => {
  const sortType = filters.get(FILTER_REDUCER_KEYS.SORT)
  const ascending = filters.get(FILTER_REDUCER_KEYS.SORT_ASCENDING)
  const starredFirst = filters.get(FILTER_REDUCER_KEYS.STARRED_FIRST)
  const starredPredicate = starredFirst ? ITEMS_SORT_PREDICATES[ITEMS_SORT_TYPE.STARRED] : () => 0
  const predicate = ITEMS_SORT_PREDICATES[sortType]
  const needsDueChecker = sortType === ITEMS_SORT_TYPE.DUE_DATE
  const predicateWithSortType = ascending ? predicate : _.flow(predicate, n => -n)
  if (needsDueChecker) {
    // It's not clean, but it's better for performance
    return (a, b) => {
      return starredPredicate(a, b) || hasDueDatePredicate(a, b) || predicateWithSortType(a, b)
    }
  }
  return (a, b) => {
    return starredPredicate(a, b) || predicateWithSortType(a, b)
  }
}

export const getGenericSortPredicateForItems = (sortType, isAscending, starredFirst) => {
  const predicate = ITEMS_SORT_PREDICATES[sortType]
  const starredPredicate = starredFirst ? ITEMS_SORT_PREDICATES[ITEMS_SORT_TYPE.STARRED] : () => 0
  const needsDueChecker = sortType === ITEMS_SORT_TYPE.DUE_DATE
  const predicateWithSortType = isAscending ? predicate : _.flow(predicate, n => -n)
  if (needsDueChecker) {
    // It's not clean, but it's better for performance
    return (a, b) => {
      return starredPredicate(a, b) || hasDueDatePredicate(a, b) || predicateWithSortType(a, b)
    }
  }
  return (a, b) => {
    return starredPredicate(a, b) || predicateWithSortType(a, b)
  }
}

export const getGenericSortPredicateForGraphResources = (sortType, isAscending) => {
  const predicate = GRAPH_RESOURCE_SORT_PREDICATES[sortType]
  const predicateWithSortType = isAscending ? predicate : _.flow(predicate, n => -n)
  return (a, b) => {
    return predicateWithSortType(a, b)
  }
}

export const getGenericSortPredicateForItemsAndGraphResources = (sortType, isAscending) => {
  const predicate = ITEM_AND_GRAPH_RESOURCE_SORT_PREDICATES[sortType]
  const needsDueChecker = sortType === ITEMS_SORT_TYPE.DUE_DATE
  const predicateWithSortType = isAscending ? predicate : _.flow(predicate, n => -n)
  if (needsDueChecker) {
    // It's not clean, but it's better for performance
    return (a, b) => {
      return hasDueDatePredicate(a, b) || predicateWithSortType(a, b)
    }
  }
  return (a, b) => {
    return predicateWithSortType(a, b)
  }
}

export const API_ACCEPTED_SORT_KEYS = new Set([
  itemHelper.KEYS.LAST_MODIFIED_TIMESTAMP,
  itemHelper.KEYS.DUE_DATE,
  itemHelper.KEYS.START_DATE,
  itemHelper.KEYS.COMPLETION_PERCENTAGE,
  itemHelper.KEYS.QUADRANT,
  itemHelper.KEYS.CREATION_DATE,
  itemHelper.KEYS.COMPLETION_DATE,
  itemHelper.KEYS.TIMESTAMP,
])

const DEFAULT_SORT_KEY = itemHelper.KEYS.LAST_MODIFIED_TIMESTAMP

export const getParamsForItemsFilters = (itemsFilters, params = {}, projects) => {
  const sortType = itemsFilters.get(FILTER_REDUCER_KEYS.SORT)
  const ascending = itemsFilters.get(FILTER_REDUCER_KEYS.SORT_ASCENDING) // ascending ? + : -

  if (!_.isEmpty(sortType)) {
    const isValid = API_ACCEPTED_SORT_KEYS.has(sortType)
    const sortKey = isValid ? sortType : DEFAULT_SORT_KEY // All sort types match the exact sort key names
    params['order_by'] = (isValid && ascending ? '' : '-') + sortKey // "-lastModifiedTimestamp" when not valid
  }

  const followed = itemsFilters.get(FILTER_REDUCER_KEYS.FOLLOWED) // ascending ? - : +
  if (followed) {
    params['following'] = 1
  }

  const state = itemsFilters.get(FILTER_REDUCER_KEYS.STATE)
  switch (state) {
    case STATE_FILTER.UNFINISHED:
      params[`${ITEM_KEYS.STATE}__lte`] = itemHelper.STATE.UNFINISHED
      break
    case STATE_FILTER.FINISHED:
      params[ITEM_KEYS.STATE] = itemHelper.STATE.DONE
      break
    default:
      // STATE_FILTER.ALL_ITEMS
      params[`${ITEM_KEYS.STATE}__lte`] = itemHelper.STATE.DONE
      break
  }

  const text = itemsFilters.get(FILTER_REDUCER_KEYS.TEXT)
  const textMode = itemsFilters.get(FILTER_REDUCER_KEYS.TEXT_MODE)
  if (!_.isEmpty(text)) {
    const param = textMode === TEXT_MODE_KEYS.EXACT_NAME ? 'name__icontains' : 'q'
    params[param] = text // Name, notes...
  }

  const owners = itemsFilters.get(FILTER_REDUCER_KEYS.OWNERS).toJS()
  const noOwner = itemsFilters.get(FILTER_REDUCER_KEYS.NO_OWNER)

  if (noOwner) {
    params['owner__in'] = '[]'
  } else if (!_.isEmpty(owners)) {
    params['owner__in'] = owners.join(',')
  }

  const startDateTimestamp = itemsFilters.get(FILTER_REDUCER_KEYS.START_DATE)
  const endDateTimestamp = itemsFilters.get(FILTER_REDUCER_KEYS.END_DATE)
  const hasStartDate = startDateTimestamp > 0
  const hasEndDate = endDateTimestamp > 0
  if (hasStartDate || hasEndDate) {
    let startDateKey, endDateKey
    let startDateValue, endDateValue
    const dateType = itemsFilters.get(FILTER_REDUCER_KEYS.DATE_TYPE)
    switch (dateType) {
      case ITEMS_DATE_TYPE.LAST_MODIFIED_DATE:
        startDateKey = `${ITEM_KEYS.LAST_MODIFIED_TIMESTAMP}__gte`
        endDateKey = `${ITEM_KEYS.LAST_MODIFIED_TIMESTAMP}__lte`
        startDateValue = timestampInSecondsToIsoString(startDateTimestamp)
        endDateValue = timestampInSecondsToIsoString(endDateTimestamp)
        break
      case ITEMS_DATE_TYPE.DUE_DATE:
        startDateKey = `${ITEM_KEYS.DUE_DATE}__gte`
        endDateKey = `${ITEM_KEYS.DUE_DATE}__lte`
        startDateValue = startDateTimestamp
        endDateValue = endDateTimestamp
        break
      case ITEMS_DATE_TYPE.START_DATE:
        startDateKey = `${ITEM_KEYS.START_DATE}__gte`
        endDateKey = `${ITEM_KEYS.START_DATE}__lte`
        startDateValue = startDateTimestamp
        endDateValue = endDateTimestamp
        break
      default:
        break
    }
    if (hasStartDate) {
      params[startDateKey] = startDateValue
    }
    if (hasEndDate) {
      params[endDateKey] = endDateValue
    }
  }

  const progress = itemsFilters.get(FILTER_REDUCER_KEYS.PROGRESS)
  if (progress > 0) {
    const progressCondition = itemsFilters.get(FILTER_REDUCER_KEYS.PROGRESS_CONDITION)
    const progressKey =
      ITEM_KEYS.COMPLETION_PERCENTAGE + (progressCondition === CONDITION_KEYS.GREATER_THAN ? '__gte' : '__lte')
    params[progressKey] = progress
  }

  const tags = itemsFilters.get(FILTER_REDUCER_KEYS.TAGS).toArray()
  if (!_.isEmpty(tags)) {
    const tagsMode = itemsFilters.get(FILTER_REDUCER_KEYS.TAGS_MODE)
    const paramKey = tagsMode === TAGS_MODE_KEYS.EXCLUDE ? 'tags_exclude' : ITEM_KEYS.TAGS
    params[paramKey] = tags
  }

  const projectsToFilter = itemsFilters.get(FILTER_REDUCER_KEYS.PROJECT_IDS).toArray()
  if (!_.isEmpty(projectsToFilter)) {
    const projectsMode = itemsFilters.get(FILTER_REDUCER_KEYS.PROJECTS_MODE)
    const param = projectsMode === PROJECTS_MODE_KEYS.EXCLUDE ? 'projects_exclude' : 'projects'
    params[param] = `${projectsToFilter.join(',')}`
  } else {
    const starredProject = itemsFilters.get(FILTER_REDUCER_KEYS.STARRED_PROJECT)
    if (starredProject) {
      params['project__tags'] = tagHelper.STARRED_TAG_NAME
    }
  }

  const quadrant = itemsFilters.get(FILTER_REDUCER_KEYS.QUADRANT)
  if (_.isNumber(quadrant) && quadrant > -1) {
    params[ITEM_KEYS.QUADRANT] = quadrant
  }

  const groupByProject = itemsFilters.get(FILTER_REDUCER_KEYS.GROUP_BY) !== GROUP_BY.NONE
  if (groupByProject && projects) {
    const projectIds = projects.map(p => projectHelper.getIdd(p))
    params['group_by'] = `_projects[${projectIds.join(',')}]`
  }

  return params
}
